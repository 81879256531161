.timer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #12395f;
    text-align: center;
}

.timer h2 {
    font-size: 2rem;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

}

.timer .time {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 65px;
}

.timer .dot {
    display: block;
    margin-left: 10px;
}

.timer span {
    font-size: 1rem;
    margin-right: 5px;
}